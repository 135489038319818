import * as React from "react"
import { NavigateFn } from "@reach/router"
import { Paddle } from "../models/Paddle"
import { Layout } from "../components/layout"
import SEO from "../components/seo"
import { Hero } from "../components/Hero"
import { ProductPageImageBlock } from "../components/ProductPageImageBlock"
import { PaddleSpecifications } from "../components/PaddleSpecifications"

interface Props {
  location: Location;
  navigate: NavigateFn;
  pageContext: { isCreatedByStatefulCreatePages: boolean, paddle: Paddle };
  path: string;
  uri: string;
}

export default ({ pageContext, location }: Props) => {
  const { paddle } = pageContext
  console.log(pageContext, location)  // fixme

  const imageTitle = `${ paddle.brand } ${ paddle.name } Paddle`

  return (
    <Layout>
      <SEO title={ `${ paddle.brand } ${ paddle.name }` } />
      <Hero title={ paddle.name } subtitle={ `by ${ paddle.brand }` } />
      <div className="column">
        <div className="columns">
          <div className="column">
            <ProductPageImageBlock imageUrls={ paddle.image_urls } title={ imageTitle } alt={ imageTitle } />
          </div>
          <div className="column">
            <PaddleSpecifications paddle={ paddle } />
          </div>
        </div>
      </div>
    </Layout>
  )
}
