import * as React from "react"
import { Image } from "../Img"
import './index.scss'

interface Props {
  imageUrls: string[];
  title: string;
  alt: string;
}

export const ProductPageImageBlock = ({ imageUrls, alt, title }: Props) => {
  const [selectedImage, setSelectedImage] = React.useState(imageUrls[0])

  return (
    <>
      <Image filePath={ selectedImage } alt={ alt } title={ title } />
      <div id="ImageBlock" className="columns is-mobile is-multiline">
        { imageUrls.map(imageUrl => (
          <div className="column is-one-quarter-mobile is-one-fifth image-thumbnail"
               key={ imageUrl }
               onClick={ () => setSelectedImage(imageUrl) }>
            <Image filePath={ imageUrl } alt={ alt } title={ title } />
          </div>
        )) }
      </div>
    </>
  )
}
